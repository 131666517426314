/*
 * Recursively finds and returns all children as an array.
 * The last element of the array is the root element.
 */
export const getAllChildren = (htmlElement: Element): Element[] => {
  if (!htmlElement.children || htmlElement.children?.length === 0) {
    return [htmlElement];
  }

  const allChildElements = [];

  for (let i = 0; i < htmlElement.children.length; i++) {
    const children = getAllChildren(htmlElement.children[i]);
    if (children) allChildElements.push(...children);
  }
  allChildElements.push(htmlElement);

  return allChildElements;
};

/*
 * Takes in a JSON string, parses it, and casts it to PropTypeClass.
 * PropTypeClass type should always be a Class definition.
 *
 * WARN: It is not possible to do such an abstract type definition
 * without `any` so I've turned off the linter for that one parameter.
 */
export const parseJSONProp = (
  prop: string | undefined | null,
  propName: string,
  PropTypeClass: any // eslint-disable-line
): typeof PropTypeClass => {
  const returnProp = new PropTypeClass();

  if (prop) {
    try {
      const propParsed = JSON.parse(prop);

      // use the class's custom fromJson method if neeeded/present
      if (PropTypeClass.fromJson) {
        return PropTypeClass.fromJson(propParsed);
      }

      // fallback to assigning all properties to the instance brute force
      return Object.assign(returnProp, propParsed);
    } catch (e) {
      console.warn(`Could not parse ${propName} with value ${prop}. Error:`, e, '\nSkipping prop.');
    }
  }
  return returnProp;
};
