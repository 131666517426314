
import {
  Dialog,
  DialogDescription,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import { mixins, Options } from 'vue-class-component';
import { CreatorInfo, getCreatorInfo, prettyWallet } from '@/api/creator';
import { subscribe } from '@/api/subscriptions';
import {
  DATA_WIDGET_VALUE,
  M_SUBSCRIBE_ERROR_EVENT,
  M_SUBSCRIBE_SUBMIT_EVENT,
  M_SUBSCRIBE_SUCCESS_EVENT
} from '@/common/constants';
import { MSubscribeProvidePropsMixin } from '@/exports/MSubscribeProps';

@Options({
  name: 'MSubscribe',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild
  }
})
export default class MSubscribe extends mixins(MSubscribeProvidePropsMixin) {
  // @dev: don't change this, it makes sure the outer divs get classes that
  //       contain your expected data-widget value no matter what
  DATA_WIDGET_VALUE: string = DATA_WIDGET_VALUE;

  // @dev: start here by replacing the data and functions with your own stuff
  state: 'subscribe' | 'success' = 'subscribe';
  email = '';
  error = '';
  creatorInfo: CreatorInfo;
  loading = false;
  isModalOpen = false;

  $refs!: {
    openModalButton: HTMLButtonElement;
    emailInput: HTMLInputElement;
  };

  async mounted(): Promise<void> {
    await this.fetchCreatorInfo(this.creatorAddress);
  }

  openModal(): void {
    this.isModalOpen = true;
    this.$refs.openModalButton.blur();

    // setTimeout(() => {
    //   this.$refs.emailInput.focus();
    // }, 500);
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.state = 'subscribe';
  }

  async fetchCreatorInfo(creatorAddress: string): Promise<void> {
    this.creatorInfo = {
      walletAddress: creatorAddress
    };
    this.creatorInfo = await getCreatorInfo(creatorAddress);
  }

  async subscribe(): Promise<void> {
    if (!this.creatorInfo.walletAddress) {
      return;
    }

    if (!this.email) {
      this.error = 'Please enter an email';
      return;
    }

    try {
      this.loading = true;
      await subscribe(this.creatorInfo.walletAddress, this.email);
      this.loading = false;
      this.state = 'success';
      window.dispatchEvent(new CustomEvent(M_SUBSCRIBE_SUCCESS_EVENT));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.loading = false;
      this.error = e.message;
      window.dispatchEvent(new CustomEvent(M_SUBSCRIBE_ERROR_EVENT));
    }
  }

  get ensOrWallet(): string {
    return this.creatorInfo?.ens
      ? this.creatorInfo.ens
      : prettyWallet(this.creatorInfo?.walletAddress ?? '');
  }

  get tweetText(): string {
    if (this.creatorInfo?.twitterUsername) {
      return `I just subscribed to @${this.creatorInfo?.twitterUsername} via @manifoldxyz ${this.tweetSubtext}`;
    }

    return `I just subscribed to ${this.ensOrWallet} via @manifoldxyz ${this.tweetSubtext}`;
  }

  async subscribeSubmit(email: string): Promise<void> {
    try {
      await subscribe(this.creatorInfo.walletAddress, email);
      window.dispatchEvent(new CustomEvent(M_SUBSCRIBE_SUCCESS_EVENT));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      window.dispatchEvent(new CustomEvent(M_SUBSCRIBE_ERROR_EVENT));
    }
  }

  created(): void {
    window.addEventListener(M_SUBSCRIBE_SUBMIT_EVENT, async (e) => {
      await this.subscribeSubmit(e.detail.email);
    });
  }

  destroyed(): void {
    window.removeEventListener(M_SUBSCRIBE_SUBMIT_EVENT, async (e) => {
      await this.subscribeSubmit(e.detail.email);
    });
  }
}
