import axios from 'axios';

const BASE_URL =
  process.env.NODE_ENV !== 'development'
    ? 'https://subscriptions.api.manifoldxyz.dev'
    : 'http://localhost:3000';

export const subscribe = async (creatorAddress: string, email: string): Promise<void> => {
  try {
    await axios.post(`${BASE_URL}/subscriptions/${creatorAddress}`, { subscriberEmail: email });
  } catch (error) {
    throw new Error('Failed to subscribe');
  }
};
