import axios from 'axios';

export interface CreatorInfo {
  walletAddress: string;
  ens?: string;
  ensAvatar?: string;
  twitterUsername?: string;
}

export const getCreatorInfo = async (walletAddress: string): Promise<CreatorInfo> => {
  const response = await axios.get(
    `https://identity.api.manifoldxyz.dev/badges?walletAddress=${walletAddress}`
  );

  if (response.status !== 200) {
    return {
      walletAddress
    };
  }

  return {
    walletAddress,
    ens: response.data.userENS ? response.data.userENS : null,
    ensAvatar: response.data.ensAvatar ? response.data.ensAvatar : null,
    twitterUsername: response.data.twitterUsername ? response.data.twitterUsername : null
  };
};

export const prettyWallet = (wallet: string): string => {
  return `${wallet.substring(0, 5)}...${wallet.substring(39, 42)}`;
};
