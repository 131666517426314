import { inject, provide } from 'vue';
import { mixins, Options, Vue } from 'vue-class-component';

type PropDefinition = {
  type: any; // eslint-disable-line
  default: any; // eslint-disable-line
};

type PropsDefinition = Record<string, PropDefinition>;

/*
 * @dev:
 *
 * Adding a new prop? Declare it in two places:
 *   1. MSubscribeOptionsProps with type and default
 *   2. MSubscribeProps (theres an obvious pattern)
 *
 * Optionally, enforce any new prop-dependency rules
 * in the created() hook of MWidgetProvidePropsMixin.
 */
export const MSubscribeOptionsProps: PropsDefinition = {
  creatorAddress: {
    type: String,
    default: false
  },
  tweetSubtext: {
    type: String,
    default: ''
  }
};

export type MSubscribeOptionsPropsType = keyof typeof MSubscribeOptionsProps;

/* @dev: Want to use an Object as a prop? Use this example pattern: */
export type ExampleType = 'something';
export class ExampleObjectProp {
  // add as many properties as you want here
  type?: ExampleType;

  // do not change this at all
  constructor(object: unknown) {
    Object.assign(this, object);
  }
}

/*
 * Defined once and extended so that the inject() and provide() calls made by
 * the two mixins in this file define all of the same things.
 */
export class MSubscribeProps extends Vue {
  // follow the pattern below when adding new props
  creatorAddress: string = MSubscribeOptionsProps.creatorAddress.default;
  tweetSubtext: string = MSubscribeOptionsProps.tweetSubtext.default;
}

/*
 * Used by WalletMixin to inject() back in all parent-provided props as variables
 * Reference: https://vuejs.org/guide/components/provide-inject.html
 */
export class MSubscribeInjectPropsMixin extends mixins(MSubscribeProps) {
  created(): void {
    // get all property definitions declared in MConnectProvidePropsMixin
    const keys = Object.keys(new MSubscribeProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      (this as any)[key] = inject(key); // eslint-disable-line
    }
  }
}

/*
 * Used by MSubscribe.vue to provide() all its props to children components
 * Reference: https://vuejs.org/guide/components/provide-inject.html
 */
@Options({ props: MSubscribeOptionsProps })
export class MSubscribeProvidePropsMixin extends mixins(MSubscribeProps) {
  /*
   * Use this created hook to enforce any prop-dependency rules.
   */
  created(): void {
    // define all properties for children who use the MConnectInjectPropsMixin
    const keys = Object.keys(new MSubscribeProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      provide(key, (this as any)[key]); // eslint-disable-line
    }
  }
}
