import useManifoldCSS from '@manifoldxyz/css-reset';
import { DATA_WIDGET_VALUE } from '@/common/constants';
import { getAllChildren, parseJSONProp } from '@/common/functions';
import MSubscribe from '@/exports/MSubscribe.vue';
import { MSubscribeOptionsProps, MSubscribeOptionsPropsType } from '@/exports/MSubscribeProps';
import { renderComponentWithApp } from '../build/mount';

// track app-component Vue instances for proper destroying later
const renderedComponents = new Map();
let widgetAttributeChangeObserver: MutationObserver;
let bodyChangeObserver: MutationObserver;

/* Mutation Handlers */

const handleWidgetAttributeChange = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName !== 'data-v-app') {
      // destroy pre-existing app-component before replacing it with new one
      destroyPotentialWidget(mutation.target as HTMLElement);
      replaceWithWidget(mutation.target as HTMLElement);
    }
  });
};

const handleDynamicDivs = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === DATA_WIDGET_VALUE) {
        replaceWithWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.pop(); // last element is root el which we've already checked above

      children.forEach((child) => {
        if ((child as HTMLElement)?.dataset?.widget === DATA_WIDGET_VALUE) {
          replaceWithWidget(child as HTMLElement);
        }
      });
    });

    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === DATA_WIDGET_VALUE) {
        destroyPotentialWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if ((child as HTMLElement)?.dataset?.widget === DATA_WIDGET_VALUE) {
          destroyPotentialWidget(child as HTMLElement);
        }
      });
    });
  });
};

/* Render and Destroy */

const replaceWithWidget = (el: HTMLElement) => {
  // grab the DOM element's data attributes to use as propsData
  const data = el.dataset;

  // parse all relevant data-attrs and typecast them into props
  const propsKeys = Object.keys(MSubscribeOptionsProps);
  const propsData: Record<string, unknown> = {};
  propsKeys.forEach((key: string): void => {
    const propDefinition = MSubscribeOptionsProps[key as MSubscribeOptionsPropsType];

    /*
     * since dataset is always a string array, we need to do some
     * typecasting and inferencing here to set propsData[key] properly
     */
    if (!data[key]) {
      propsData[key] = propDefinition.default;
      return;
    }

    if (propDefinition.type === String) {
      propsData[key] = data[key];
      return;
    }

    if (propDefinition.type === Number) {
      propsData[key] = JSON.parse(data[key] as string);
      return;
    }

    if (propDefinition.type === Boolean) {
      propsData[key] = JSON.parse(data[key] as string);
      return;
    }

    propsData[key] = parseJSONProp(data[key], key, propDefinition.type);
  });

  // render the component
  const renderedComponent = renderComponentWithApp({
    el: el,
    component: MSubscribe,
    props: propsData
  });
  renderedComponents.set(el, renderedComponent);

  // observe any attribute changes and rerender accordingly
  const config = {
    attributes: true,
    childList: false,
    subtree: false
  };
  widgetAttributeChangeObserver.observe(el, config);
};

const destroyPotentialWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component for memory's sake
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

/* Main Script */

const main = (): void => {
  // MutationObserver gets reused by every rendered component
  widgetAttributeChangeObserver = new window.MutationObserver(handleWidgetAttributeChange);

  // manually sweep the DOM for pre-existing widget divs that need replacing
  const elements = document.querySelectorAll(`[data-widget=${DATA_WIDGET_VALUE}]`);
  elements.forEach((el: Element) => {
    replaceWithWidget(el as HTMLElement);
  });

  // listen for new widget divs being added to the document that need replacing
  const bodyNode = document.querySelector('body');
  if (bodyNode && !bodyChangeObserver) {
    const config = {
      childList: true,
      subtree: true
    };
    bodyChangeObserver = new window.MutationObserver(handleDynamicDivs);
    bodyChangeObserver.observe(bodyNode, config);
  }
};

if (window) {
  // @dev: if you want default styles, call with { reset: 'all', styles: 'all' }
  useManifoldCSS();

  // ref: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/loadEventEnd
  const alreadyLoaded = performance
    .getEntriesByType('navigation')
    .every((e) => (e as PerformanceNavigationTiming).loadEventEnd);

  if (alreadyLoaded) {
    // the `load` event already fired so we're ready to do main() instantly
    main();
  } else {
    // we only want to call main() after the page `load` fires
    window.addEventListener('load', main);
  }
}
